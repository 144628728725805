<template data-app>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Лиды</v-card-title>
        <v-card-actions>
          <DatePeriod @change="getLeads(false)" v-model="date"></DatePeriod>
        </v-card-actions>
        <v-card-text>
          <v-chip @click="changeStatus(index)"
                  v-for="(statusTitle, index) in $store.state.leadStatuses" :color="status===index ? '#9BF0F5' : '#fff'"
                  class="mr-2" label>
            {{ statusTitle.title }}
            <v-badge v-if="index===0 && rows.filter(row => row.status == index).length" style="" inline
                     :color="index===0 && rows.filter(row => row.status == index).length ? 'red':''"
                     :content="rows.filter(row => row.status == index && row.leads[0].type!='sms').length"></v-badge>
          </v-chip>
          <v-btn @click="$eventBus.$emit('sendSMS', selectedAndDisplayedPhones.map(item=>item.client && item.client.n_id ? item.client : {phone: item.phone}))" style="background-color: #2196f3!important;color: white!important;">
            Отправить смс ({{selectedAndDisplayedPhones.length}})
          </v-btn>
          <div class="mt-2">
            <v-chip v-if="status==2" @click="today = !today;getLeads(false);" :color="today ? '#9BF0F5' : '#fff'"
                    class="mr-2" label>
              На этот день
              <v-badge v-if="leads_waiting" style="border-radius: 5px;margin-bottom: -2px" inline color="orange"
                       :content="leads_waiting"></v-badge>
            </v-chip>
            <v-chip @click="status2 = (status2===index ? '' : index)" :color="status2==index ? '#9BF0F5' : '#fff'"
                    class="mr-2" label v-if="status==3" v-for="(statusItem, index) in $store.state.leadStatuses2">
              {{ statusItem.text || 'Все' }}
            </v-chip>
            <v-chip @click="status3 = (status3===index ? '' : index)" :color="status3==index ? '#9BF0F5' : '#fff'"
                    class="mr-2" label v-if="status==4"
                    v-for="(statusTitle, index) in $store.state.leadInappropriateType">
              {{ statusTitle || 'Все' }}
            </v-chip>
          </div>
          <v-data-table
              hover
              :items="displayedRows"
              :headers="headers"
              item-key="id"
              :loading="loading"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Лидов нет"
              loading-text="Загрузка..."
              expand-icon="mdi-chevron-up"
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <LeadRow :selected="selectedPhones.includes(item.phone)" :on-select="onSelect" :onExpand="(phone)=>expanded = expanded===phone ? '' : phone" :item="item"
                       :index="index"
                       :expanded="expanded" :lead="item.leads[0]"/>
              <LeadRow v-for="(lead, leadKey) in item.leads" v-if="leadKey>0 && item.phone==expanded" :lead="lead"
                       :leadKey="leadKey" :key="leadKey+'-'+index" :item="item" :index="index"/>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import DatePeriod from "../../components/DatePeriod";
import ClientLink from "../../components/ClientLink";
import OrderLink from "../../components/OrderLink";
import LeadRow from "../../components/LeadRow";

export default {
  name: "Leads",
  components: {
    LeadRow,
    OrderLink,
    ClientLink,
    DatePeriod
  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      headers: [
        {text: '№', value: 'index'},
        {text: 'Статус', value: 'type'},
        {text: 'Дата', value: 'leads[0].datetimeObj.str'},
        {text: 'Клиент', value: 'client'},
        {text: 'Инфо', value: 'item'},
        {text: 'Менеджер', value: 'manager'},
      ],
      expanded: '',
      loading: false,
      rows: [],
      status: '',
      status2: '',
      status3: '',
      selectedPhones: [],
      lead_waiting_today: '',
      today: '',
      date: [today, today],
    }
  },
  mounted() {
    this.getLeads()
    this.$eventBus.$on('leadSaved', () => this.getLeads(false));
  },
  methods: {
    changeStatus(index) {
      let today = this.today;
      this.status = (this.status === index ? '' : index)
      this.today = (this.status === 2);
      if (today !== this.today) this.getLeads(false);
    },
    onSelect(item) {
      var index = this.selectedPhones.indexOf(item.phone);
      if (index !== -1) {
        this.selectedPhones.splice(index, 1);
      } else {
        this.selectedPhones.push(item.phone);
      }
      console.log(this.selectedPhones);
    },
    getLeads(firstTime = true) {
      this.$store.state.server.request('lead/get2', {date: this.date, today: this.today ? 1 : 0}, (data) => {
        this.rows = data.response;
        this.leads_waiting = data.leads_waiting;
        this.loading = false;
        if (firstTime) setTimeout(() => {
          if (this.$router.currentRoute.path === '/leads') this.getLeads()
        }, 5000)
      }, (data) => {
        if (firstTime) setTimeout(() => {
          if (this.$router.currentRoute.path === '/leads') this.getLeads()
        }, 5000)
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },

  },
  computed: {
    displayedRows() {
      return this.rows.filter(
          row => {
            return this.status === '' ||
                (
                    row.status === this.status &&
                    row.leads[0].type !== 'sms' &&
                    (this.status !== 3 || !this.status2 || this.status2 === row.status2 || this.status2 * 1 && row.leads.some(lead => lead.item && lead.item.comment && lead.item.comment.includes && lead.item.comment.includes(this.$store.state.leadStatuses2[this.status2].text))) &&
                    (this.status !== 4 || !this.status3 || this.status3 === row.status3)
                )
          }
      )
    },
    selectedAndDisplayedPhones() {
      return this.displayedRows.filter(item => this.selectedPhones.includes(item.phone));
    }
  }
}
</script>

<style scoped>

</style>